import VueRouter from 'vue-router'
import AppLayout from './components/AppLayout.vue'
import websiteHome from './views/websiteHome'
import contactUs from './views/contactUs'





const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      { path: '/', component: websiteHome },
      { path: '/home', component: websiteHome },
      // { path: '/contact', component: contactUs },
    ],
  },
  {
    path: '/contact',
    component: contactUs,
  },
]

const router = new VueRouter({
  routes, // 对应到你之前定义的 routes 常量
  mode: 'history' // 使用HTML5的History路由模式
})
export default router
