<template>
  <div class="out-box">
    <!-- 返回首页及返回顶部 -->
    <div class="goBack-box">
      <div class="goBack" @click="goBack">
        <img
          class="goBack-img"
          src="../assets/companyPage/goheadPage.png"
          alt=""
        />
        <div class="goBack-word">返回首页</div>
      </div>
      <div href="" @click="goTop" class="goTop">
        <img class="goTop-img" src="../assets/companyPage/goTop.png" alt="" />
      </div>
    </div>
    <div>
      <div class="introductingCompany1">
        <div class="introduce">
          <h2 class="introduce-title">北京缔智元科技</h2>
          <div class="introduce-content">
            北京缔智元科技有限公司的核心产品方向是为各类企业场景提供AI驱动的数字员工。应用AIGC、机器视觉、自然语言交互、超写实3D渲染、物联网智能办公等前沿技术,为企业提供有“温度”的服务，助力企业降本增效与智能化变革。
          </div>
        </div>
      </div>
      <div class="introduce-team-box">
        <h2 class="introduce-team-title">公司介绍</h2>
        <div class="introduce-team-content">
          企业致力于向各类行业场景提供，由AI大模型驱动的，实时互动的数字人员工。应用AIGC、多模态识别、超写实3D渲染、物联网等前沿技术，为各行各业提供功能强大且有“温度”的服务，助力客户降本增效与智能化变革。
        </div>
        <div class="introduce-team-content">
          创始核心团队均毕业于清华大学，曾分别就职于微软、谷歌、Juniper、联想、神州数码、Tom.com等国内外知名高科技企业，具有极其丰富的ToB产品研发服务经验。
        </div>
        <div class="introduce-people-box">
          <div class="introduce-people">
            <img
              class="people-img"
              src="../assets/companyPage/pic1.jpg"
              alt=""
            />
            <div class="name">朱建阳</div>
            <div class="position">CEO/联合创始人</div>
            <div class="educational">毕业于清华大学计算机系</div>
            <div class="brief-introduce">
              拥有近30年企业级软件和云服务的行业和管理经验，曾在微软中国、惠普、神州数码等公司担任高层管理职位，具有德稻全球创新网络、银杏树科技、车百科技等初创公司的创业经历。
            </div>
          </div>
          <div class="introduce-people">
            <img
              class="people-img"
              src="../assets/companyPage/pic2.jpg"
              alt=""
            />
            <div class="name">王全勇</div>
            <div class="position">首席科学家/合伙人</div>
            <div class="educational">清华大学数学系学士、硕士</div>
            <div class="brief-introduce">
              25年软件开发与设计经验，曾在中软、RMG等国内外知名企业担任技术管理工作。具有丰富的软件架构设计及新技术研究及探索经验。
            </div>
          </div>
          <div class="introduce-people">
            <img
              class="people-img"
              src="../assets/companyPage/pic3.jpg"
              alt=""
            />
            <div class="name">林添</div>
            <div class="position">资深技术顾问/合伙人</div>
            <div class="educational">清华大学学士、硕士、博士</div>
            <div class="brief-introduce">
              GoogleBrain/DeepMind技术专家，TensorFlow中国团队联合创始人，曾在顶级会议ICML、NeurIPS、ICLR、KDD等机器学习相关领域发表多篇学术论文。
            </div>
          </div>
          <div class="introduce-people">
            <img
              class="people-img"
              src="../assets/companyPage/pic4.jpg"
              alt=""
            />
            <div class="name">戴冠平</div>
            <div class="position">行业大模型专家</div>
            <div class="educational">清华大学计算机系硕士</div>
            <div class="brief-introduce">
              先后任职于中国科学院软件研究所、BEA/ORACLE(中国);二十余年人工智能基础性研究工作经验，在大模型的研究与应用落地研究拥有丰富的实践经验;擅长多个模态上数据对齐、融合与智能学习。
            </div>
          </div>
        </div>
      </div>
      <div :class="showMoreInfo" class="company-news">
        <div class="company-news-title">公司动态</div>
        <div class="news-list-box">
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/IWBdvGx_wctwKAw2AWV6ZQ')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/robot.png"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2024</div>
              <div>08-20</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">AI抢了谁的饭碗？</div>
              <div class="news-info">
                2023年初，chatGPT3.5在世界范围内的一夜爆红,让所有人惊叹科幻电影中预测的场景提前到来。很多人,包括不少AI从业者,认为以chatGPT为代表的大模型的出现会让成千上万的人失业，将重构未来世界的产业架构与就业格局，“将成为人类历史上最重要的dis-ruptive
                innovation”。甚至父母在给孩子确定大学专业的时候都会要问一句:“哪些专业今后不会被AI抢了饭碗?”
              </div>
            </div>
          </div>
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/ErwkvR3ZjmB2r8uZd9AV3g')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/cidpe.jpg"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2023</div>
              <div>05-29</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">
                缔智元科技数字人:福州数字峰会的智慧新星
              </div>
              <div class="news-info">
                第七届数字中国建设峰会在福州盛大开幕，以“释放数据要素价值,发展新质生产力”为主题，深度探索数字经济与实体经济的融合之道。在总面积达5.6万平方米的展区中，高科技产品云集。缔智元的数字人产品首次在数字峰会亮相,引人注目,成为本届峰会的智慧新星。
              </div>
            </div>
          </div>
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/BAD3jGp6QTE4oUGRMqSKLA')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/B2.jpg"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2024</div>
              <div>05-23</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">产业园区智能化服务新星:企业数字员工</div>
              <div class="news-info">
                缔智元科技为东升科技园带来了一位独特的“数字人”员工,这位员工已经入职东升科技园半年之久，她为园区的日常运营和服务注入了新的活力。
              </div>
            </div>
          </div>
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/NcnloOzwF3mz43sebk3khA')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/pic5.jpg"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2024</div>
              <div>04-19</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">
                人工智能大模型发展趋势与企业级市场应用前景研讨会圆满落幕
              </div>
              <div class="news-info">
                缔智元创始人CEO朱建阳在会场上为大家详细讲解了大模型在数字人领域的应用。他通过生动的案例展示了数字人在客户服务、会议预定、机场问询等方面的实际应用,让现场观众深刻感受到了大模型技术为数字人带来的快速发展。
              </div>
            </div>
          </div>
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/u9XJQumuVDzslK0ib1CPgQ')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/pic6.jpg"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2023</div>
              <div>09-22</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">
                数字人2.0版本:大脑、视觉和听觉能力全面提升
              </div>
              <div class="news-info">
                数字人2.0版本还引入了自然语言合成的能力,当用户提出问题时,数字人可以通过大模型的泛化理解，生成自然语言与访客进行流畅的对话。
              </div>
            </div>
          </div>
          <div @click="clickNews()" class="news-list">
            <!-- <img
              class="news-list-img"
              src="../assets/companyPage/pic7.jpg"
              alt=""
            /> -->
            <video
              class="news-list-img"
              controls
              src="../assets//companyPage/void.mp4"
            ></video>
            <div class="news-list-time">
              <div class="news-list-time-year">2023</div>
              <div>09-18</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">缔智元企业数字员工1.0版本上线!</div>
              <div class="news-info">
                缔智元科技的数字员工1.0,正是瞄准了中小企业对企业前台的需求,用数字员工替代传统员工,完成访客预约与接待、会议室预定及会议组织、员工考勤管理、门禁及隐形视频监控、办公室场景控制、公共信息咨询等前台行政人员的重复性工作。
              </div>
            </div>
          </div>
          <div
            style="cursor: pointer"
            @click="
              clickNews('https://mp.weixin.qq.com/s/KyHxvhfQV6pvs116FHX1zA')
            "
            class="news-list"
          >
            <img
              class="news-list-img"
              src="../assets/companyPage/pic8.jpg"
              alt=""
            />
            <div class="news-list-time">
              <div class="news-list-time-year">2023</div>
              <div>08-30</div>
            </div>
            <div class="news-list-content">
              <div class="news-title">数字员工产品开创新前台工作模式</div>
              <div class="news-info">
                “让每一家企业都拥有属于自己的数字员工是缔智元团队的创业理想”缔智元科技的数字员工“小酷“于今年二月份入职阳光恒昌,半年时间中,“小酷“学习新的技能,了解企业文化、提高各种业务能力,终于通过了试用期，成为一名正式的前台工作人员。
              </div>
            </div>
          </div>
        </div>
        <div
          @click="showMore"
          v-show="showMoreInfo == ''"
          class="show-more-box"
        >
          <div class="show-more">
            查看更多动态<img
              style="height: 1.3vw"
              src="../assets/companyPage/moreInfo.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMoreInfo: "",
    };
  },
  methods: {
    showMore() {
      this.showMoreInfo = "no-height";
    },
    clickNews(e) {
      if (e) {
        window.open(e);
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    goTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 可选，平滑滚动效果
      });
    },
  },
};
</script>

<style scoped>
@media screen and (orientation: landscape) {
  .out-box {
    width: 100%;
  }
  .goBack-box {
    text-align: center;
    position: fixed;
    right: 2vw;
    bottom: 20vh;
    width: 5vw;
    height: 10vw;
    background-color: rgb(15, 138, 205);
    border-radius: 8px;
    color: #fff;
    z-index: 99;
  }
  .goBack {
    border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .goBack-img {
    width: 3vw;
  }
  .goBack-word {
    display: none;
  }
  .goTop-img {
    width: 3vw;
  }
  .goTop {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .introductingCompany1 {
    width: 100%;
    height: 41.67vw;
    background-image: url("../assets//companyPage/introductingCompany1.jpg");
    background-size: 100% 100%;
  }
  .introduce {
    width: 51vw;
    color: #fff;
    padding-top: 16.67vw;
    padding-left: 12.5vw;
  }
  .introduce-title {
    font-size: 2.2vw;
  }
  .introduce-content {
    font-size: 1.2vw;
    line-height: 2.5vw;
  }
  .introduce-team-box {
    margin-top: 10vh;
    background-image: url("../assets/companyPage/introductingCompany3.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 74vw;
    padding-top: 7.81vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .introduce-team-title {
    width: 74.9vw;
    font-size: 2.2vw;
    font-weight: 900;
    text-align: center;
  }
  .introduce-team-content {
    width: 74.9vw;
    font-size: 1.62vw;
    text-align: center;
    margin-top: 5vh;
  }
  .introduce-people-box {
    width: 74.9vw;
    height: 38.96vw;
    margin-top: 4.38vw;
    display: flex;
    justify-content: space-between;
  }
  .introduce-people {
    width: 18.07vw;
    height: 100%;
    background-color: #fff;
  }
  .people-img {
    width: 100%;
  }
  .name {
    width: 100%;
    font-size: 1.32vw;
    font-weight: 900;
    text-align: center;
  }
  .position {
    width: 100%;
    font-size: 1.32vw;
    font-weight: 900;
    text-align: center;
  }
  .educational {
    text-align: center;
    font-size: 1.1vw;
    margin-top: 0.5vw;
  }
  .brief-introduce {
    padding: 0.5vw 1vw 0;
    font-size: 1.1vw;
    overflow: auto;
    height: 11vw;
  }
  .company-news {
    width: 100%;
    padding-top: 5vw;
    height: 107vh;
    overflow: hidden;
    position: relative;
  }
  .no-height {
    height: auto !important;
  }
  .company-news-title {
    text-align: center;
    font-size: 2.2vw;
    font-weight: 900;
  }
  .show-more-box {
    cursor: pointer;
    position: absolute;
    top: 101vh;
    background-color: rgb(2, 0, 37);
    border-radius: 2vw;
    color: #fff;
    left: 42vw;
    height: 6vh;
    width: 16vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .show-more {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    font-size: 1.3vw;
  }
  .news-list {
    width: 74.9vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12.55vw;
    margin-top: 5vw;
    margin-top: 7vh;
    height: 21vh;
  }
  .news-list-img {
    width: 16.57vw;
  }
  .news-list-time {
    font-size: 1.62vw;
    border-right: 1px dashed #ccc;
    padding-right: 3vw;
    color: #ccc;
  }
  .news-list-time-year {
    font-size: 2.2vw;
    font-weight: 600;
    color: #000;
  }
  .news-list-content {
    width: 38.54vw;
    font-size: 1.1vw;
  }
  .news-title {
    font-size: 1.62vw;
    font-weight: 700;
  }
}
@media screen and (orientation: portrait) {
  .out-box {
    width: 100%;
  }
  .goBack-box {
    text-align: center;
    position: fixed;
    right: 2vw;
    bottom: 20vh;
    width: 8vw;
    height: 15vw;
    background-color: rgb(15, 138, 205);
    border-radius: 1vw;
    z-index: 99;
    color: #fff;
  }
  .goBack {
    border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1vw;
  }
  .goBack-img {
    width: 4.5vw;
  }
  .goBack-word {
    display: none;
  }
  .goTop-img {
    width: 4.5vw;
  }
  .goTop {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .introductingCompany1 {
    width: 100%;
    height: 41.67vw;
    background-image: url("../assets//companyPage/introductingCompany1.jpg");
    background-size: 100% 100%;
  }
  .introduce {
    width: 51vw;
    color: #fff;
    padding-top: 16.67vw;
    padding-left: 12.5vw;
  }
  .introduce-title {
    font-size: 2.2vw;
  }
  .introduce-content {
    font-size: 1.2vw;
    line-height: 2.5vw;
  }
  .introduce-team-box {
    /* margin-top: 10vh; */
    background-image: url("../assets/companyPage/introductingCompany3.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 74vw;
    /* height: 64.9vw; */
    padding-top: 7.81vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .introduce-team-title {
    width: 74.9vw;
    font-size: 2.2vw !important;
    font-weight: 900 !important;
    text-align: center;
  }
  .introduce-team-content {
    width: 74.9vw;
    font-size: 1.62vw;
    text-align: center;
  }
  .introduce-people-box {
    width: 74.9vw;
    height: 38.96vw;
    margin-top: 4.38vw;
    display: flex;
    justify-content: space-between;
  }
  .introduce-people {
    width: 18.07vw;
    height: 100%;
    background-color: #fff;
  }
  .news-info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .people-img {
    width: 100%;
  }
  .name {
    width: 100%;
    font-size: 1.62vw;
    font-weight: 900;
    text-align: center;
  }
  .position {
    width: 100%;
    font-size: 1.62vw;
    font-weight: 900;
    text-align: center;
  }
  .educational {
    text-align: center;
    font-size: 1.4vw;
  }
  .brief-introduce {
    padding: 1vw 1vw 0;
    font-size: 1.1vw;
    overflow: auto;
    height: 11vw;
    /* display: none; */
  }
  .company-news {
    width: 100%;
    padding-top: 5vw;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
  }
  .company-news-title {
    text-align: center;
    font-size: 2.2vw;
    font-weight: 900;
  }
  .news-list {
    width: 74.9vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12.55vw;
    margin-top: 5vw;
  }
  .news-list-img {
    width: 20.57vw;
  }
  .news-list-time {
    font-size: 1.62vw;
    border-right: 1px dashed #ccc;
    padding-right: 3vw;
    color: #ccc;
  }
  .news-list-time-year {
    font-size: 2.2vw;
    font-weight: 600;
    color: #000;
  }
  .news-list-content {
    width: 38.54vw;
    font-size: 1.1vw;
  }
  .news-title {
    font-size: 1.62vw;
    font-weight: 800;
  }
  .show-more-box {
    display: none;
  }
}
</style>