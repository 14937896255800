<template>
  <div>
    <header
      id="header"
      v-bind:class="[flag ? 'isActive' : '', 'fixed-top']"
      ref="scrollElement"
      v-show="!showCompany"
    >
      <div class="w1400">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="#">
            <img src="/images/logo.png" alt="" class="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            v-on:click="toggleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarNavDropdown"
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section9')"
                  >公司介绍 <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section1')"
                  >核心能力 <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section2')"
                  >应用场景</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section7')"
                  >成功案例</a
                >
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section10')"
                  >企业动态 <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  @click="scrollToSection('section8')"
                  >联系我们</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
    <router-view v-show="!showCompany" />
    <div v-show="showCompany">
      <introdCompany @goBack="goBack" />
    </div>
    <footer>
      <div id="section8" style="padding-top: 4vh" class="container">
        <div style="padding: 15px" class="row foot-box">
          <div
            style="padding: 0; max-width: 30%"
            class="col col-md-4 foot-logo"
          >
            <div style="text-align: start" class="f_left">
              <img style="width: 200px" src="../assets/logo.png" alt="" />
              <p style="font-size: 26px; margin-top: 24px">缔智元科技</p>
            </div>
          </div>
          <div style="padding: 0" class="col col-md-4 foot-connect">
            <div class="f_right">
              <div style="font-size: 26px">联系我们</div>
              <p style="margin: 20px 0" class="usp">电话：18500028288</p>
              <div style="margin: 20px 0">
                邮箱：
                <!-- <div ></div> -->
                <a href="http://marketing@digimeta.com.cn"
                  >marketing@digimeta.com.cn</a
                >
                <!-- <span style="color: skyBlue">marketing@digimeta.com.cn</span> -->
              </div>
              <p class="usp">
                地址：北京市海淀区中关村东升科技园-北领地B3楼303
              </p>
            </div>
          </div>
          <div
            style="
              padding: 0;
              display: flex;
              justify-content: space-around;
              max-width: 36%;
            "
            class="col foot-code"
          >
            <div
              style="display: flex; flex-direction: column; align-items: end"
              class="f_left"
            >
              <img style="width: 100px" src="../assets/wxCode.png" alt="" />
              <p style="width: 100px; text-align: center">
                <span style="font-size: 14px">商务合作</span>
              </p>
            </div>
            <div
              style="display: flex; flex-direction: column; align-items: end"
              class="f_left"
            >
              <img style="width: 100px" src="/images/code.jpg" alt="" />
              <p style="width: 100px; text-align: center">
                <span style="font-size: 14px">扫码关注公众号</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import introdCompany from "../views/introdCompany.vue";

Vue.use(VueScrollTo);
export default {
  name: "topBar",
  components: {
    introdCompany,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      showCompany: false,
      scrollListener: null,
      flag: false,
      // show:false,  我自己写的
    };
  },
  mounted() {
    this.scrollListener = () => {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 150) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    };
    window.addEventListener("scroll", this.scrollListener);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    goBack() {
      console.log(2222);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 可选，平滑滚动效果
      });
      this.showCompany = !this.showCompany;
    },
    introductingCompany() {
      console.log(11111);
      this.showCompany = !this.showCompany;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 可选，平滑滚动效果
      });
    },
    scrollToSection(sectionId) {
      if (window.innerWidth < 991) {
        // this.show = false;
        this.$scrollTo(`#${sectionId}`, 500, { offset: -358 });
      } else {
        this.$scrollTo(`#${sectionId}`, 500, { offset: -85 });
      }
    },
    toggleNavbar() {
      console.log("1111");
      this.show = !this.show;
      console.log(this.show);
    },
  },
};
</script>


<style scoped>
header {
  height: 80px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
.w1400 {
  width: 100%;
  margin: auto;
}
.nav-item {
  margin-right: 10px;
}
.nav-link {
  color: #fff !important;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  transition: transform 0.2s linear;
}
.nav-link:hover {
  transform: scale(1.1);
}
.logo {
  /* width:50px; */
  height: 45px;
}
/* 底部样式 */
footer {
  background: #181818;
  color: #fff;
  margin-top: 100px;
}
footer img {
  width: 100px;
}
.f_left {
  text-align: center;
  margin: 85px 0;
}
.f_right {
  margin: 85px 0;
  font-size: 14px;
}
.isActive {
  background-color: #fff;
  color: #333;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.isActive ul li a {
  color: #333 !important;
}
@media screen and (max-width: 991px) {
  /* 在这里写入当屏幕宽度小于或等于991px时的CSS规则 */
  .w1400 {
    width: 100%;
  }
  header {
    height: 60px;
    background: #fff !important;
  }
  header ul {
    font-size: 12px;
    background: #fff;
    display: flex;
    /* opacity: 0.6; */
    /* background: rgba(144, 82, 82, 0.2); */
    border-radius: 10px;
    padding: 16px;
    border: 0.5px solid;
  }
  header ul li {
    /* background-color: aqua; */
    /* border-bottom: 1px solid black; */
    color: #0d0d0d;
    text-align: center;
  }
  #header ul li a {
    color: #0d0d0d !important;
    border-radius: 0%;
    background: none;
    /* background-color: aquamarine; */
  }
  /* 导航栏 样式 */

  /* 其他样式 */
  .logo {
    width: 100px;
    height: 28px;
  }
  /* 底部样式 */
  footer {
    background: #181818;
    color: #fff;
    margin-top: 90px;
  }
  /* footer img {
    width: 80px;
    height: 80px;
  } */
  .f_right {
    font-size: 12px;
  }
}
@media screen and (orientation: portrait) {
  .foot-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .foot-logo {
    display: none;
  }
  .foot-connect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15vh;
  }
  .foot-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
