<template>
  <div>
    <div class="part1">
      <video
        src="../assets/headPage/nomalVideo.mp4"
        autoplay
        muted
        loop
        class="d-block w-100"
        @click="clickNormalVideo"
        v-if="showNormalVideo"
      ></video>
      <video
        v-if="showSpeakVideo"
        ref="videoPlayer"
        src="../assets/headPage/speakVideo.mp4"
        style="position: absolute; top: 0"
        class="d-block w-100"
        @ended="speakEnd"
      ></video>
      <div @click="clickNormalVideo" class="product-introduction">
        <h3 class="top-title">“懂行”的数字人</h3>
        <ul class="top-body">
          <li>自然语言实时交互</li>
          <li>超写实的拟人形象</li>
          <li>多模态感知融合场景智能</li>
          <li>AI驱动的自我学习与进化</li>
        </ul>
      </div>
    </div>
    <div class="part2">
      <div class="container Function" id="section1">
        <h2 class="title title1 success">数字人四大核心能力</h2>
        <div class="row">
          <div class="col-6 col-md-3 col-sm-6">
            <div
              class="card"
              style="width: 95%; border: 1.5px solid rgb(243, 183, 173)"
            >
              <div class="card-body">
                <div style="text-align: center">
                  <img
                    style="width: 50%"
                    src="../assets/headPage/2.png"
                    alt=""
                  />
                </div>
                <h5 class="card-title">多模态识别</h5>
                <p class="card-text">
                  自研<strong>面部视觉算法</strong>，在身份鉴权的能力之上，对距离深度、面部XYZ轴偏角、眼球角度、情绪指数等特殊信息进行采集和分析，与自有知识产权动态定向对话算法结合，形成多模态识别，对用户意图，情绪精准判别。让数字人象真人一样具备感知与自然语言交流能力。
                </p>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-3 col-sm-6">
            <div
              class="card"
              style="width: 95%; border: 1.5px solid rgb(248, 176, 66)"
            >
              <div class="card-body">
                <div style="text-align: center">
                  <img
                    style="width: 50%"
                    src="../assets/headPage/3.png"
                    alt=""
                  />
                </div>
                <h5 class="card-title">行业大模型</h5>
                <p class="card-text">
                  大模型是数字人的大脑。“<strong>千树</strong>”行业大模型，通过垂直行业数据的全面训练，使数字人成为行业专家，为用户提供全面的服务。
                </p>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-3 col-sm-6">
            <div
              class="card"
              style="width: 95%; border: 1.5px solid rgb(157, 189, 186)"
            >
              <div class="card-body">
                <div style="text-align: center">
                  <img
                    style="width: 50%"
                    src="../assets/headPage/1.png"
                    alt=""
                  />
                </div>
                <h5 class="card-title">超写实形象</h5>
                <p class="card-text">
                  通过AI驱动<strong>超写实人物形象</strong>的口型、表情及动作，避免“恐怖谷效应”，同时满足交互的时延要求，使交互形象真实而亲切。
                </p>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-3 col-sm-6">
            <div
              class="card"
              style="width: 95%; border: 1.5px solid rgb(129, 123, 217)"
            >
              <div class="card-body">
                <div style="text-align: center">
                  <img
                    style="width: 50%"
                    src="../assets/headPage/4.png"
                    alt=""
                  />
                </div>
                <h5 class="card-title">IoT联接万物</h5>
                <p class="card-text">
                  数字人最大的想象力是能够走出数字虚拟世界，与物理世界进行互动，<strong>通过对物联网的感知与控制</strong>，为用户提供更有效的帮助。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part3" id="section2">
      <h2 class="title title1"></h2>
      <div class="container">
        <div style="position: relative" class="row">
          <img
            src="../assets/headPage/img1.jpg"
            alt=""
            width="100%"
            style="border-radius: 10px"
          />
          <div class="img_word">
            <h2>机场数字人</h2>
            <p>
              航班信息&nbsp;&nbsp;&nbsp;&nbsp; 机场导航&nbsp;&nbsp;&nbsp;&nbsp;
              商户推荐&nbsp;&nbsp; &nbsp;&nbsp; 出行规划&nbsp;&nbsp;&nbsp;&nbsp;
              法规解说&nbsp;&nbsp;&nbsp;&nbsp; 机器人调度&nbsp;&nbsp;
              &nbsp;&nbsp; 联接人工客服
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="part3" id="section2">
      <h2 class="title title1"></h2>
      <div class="container">
        <div style="position: relative" class="row">
          <img
            src="../assets/headPage/img2.jpg"
            alt=""
            width="100%"
            style="border-radius: 10px"
          />
          <div style="width: 45.4%" class="img_word">
            <h2>企业数字员工</h2>
            <p>
              公司前台&nbsp;&nbsp;&nbsp;&nbsp; 行政助理&nbsp;&nbsp;&nbsp;&nbsp;
              智能办公&nbsp;&nbsp;&nbsp;&nbsp; 安防管理
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="part3" id="section2">
      <h2 class="title title1"></h2>
      <div class="container">
        <div style="position: relative" class="row">
          <img
            src="../assets/headPage/img3.jpg"
            alt=""
            width="100%"
            style="border-radius: 10px"
          />
          <div style="width: 45.4%" class="img_word">
            <h2>展厅数字人</h2>
            <p>
              展厅讲解引导&nbsp;&nbsp;&nbsp;&nbsp;
              大模型智能问答&nbsp;&nbsp;&nbsp;&nbsp; IoT展厅联动
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="part3" id="section2">
      <h2 class="title title1"></h2>
      <div class="container">
        <div style="position: relative" class="row">
          <img
            src="../assets/headPage/img4.jpg"
            alt=""
            width="100%"
            style="border-radius: 10px"
          />
        </div>
      </div>
    </div>
    <div class="part8" id="section7">
      <h2 class="title title1 success">成功案例</h2>
      <div class="containe">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 col-sm-6">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success1.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div class="col-12 col-md-4 col-sm-6 company">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success2.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div class="col-12 col-md-4 col-sm-6 company">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success3.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 col-sm-6">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success4.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div class="col-12 col-md-4 col-sm-6 company">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success5.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div class="col-12 col-md-4 col-sm-6 company">
              <div class="card" style="width: 100%">
                <img
                  src="../assets/headPage/success6.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="out-box">
      <!-- 返回首页及返回顶部 -->
      <!-- <div class="goBack-box">
        <div class="goBack" @click="goBack">
          <img
            class="goBack-img"
            src="../assets/companyPage/goheadPage.png"
            alt=""
          />
          <div class="goBack-word">返回首页</div>
        </div>
        <div href="" @click="goTop" class="goTop">
          <img class="goTop-img" src="../assets/companyPage/goTop.png" alt="" />
        </div>
      </div> -->
      <div>
        <!-- <div class="introductingCompany1">
          <div class="introduce">
            <h2 class="introduce-title">北京缔智元科技</h2>
            <div class="introduce-content">
              北京缔智元科技有限公司的核心产品方向是为各类企业场景提供AI驱动的数字员工。应用AIGC、机器视觉、自然语言交互、超写实3D渲染、物联网智能办公等前沿技术,为企业提供有“温度”的服务，助力企业降本增效与智能化变革。
            </div>
          </div>
        </div> -->
        <div id="section9" class="introduce-team-box">
          <h2 class="introduce-team-title">公司介绍</h2>
          <div class="introduce-team-content">
            企业致力于向各类行业场景提供，由AI大模型驱动的，实时互动的数字人员工。应用AIGC、多模态识别、超写实3D渲染、物联网等前沿技术，为各行各业提供功能强大且有“温度”的服务，助力客户降本增效与智能化变革。
          </div>
          <div class="introduce-team-content">
            创始核心团队均毕业于清华大学，曾分别就职于微软、谷歌、Juniper、联想、神州数码、Tom.com等国内外知名高科技企业，具有极其丰富的ToB产品研发服务经验。
          </div>
          <div class="introduce-people-box">
            <div class="introduce-people">
              <img
                class="people-img"
                src="../assets/companyPage/pic1.jpg"
                alt=""
              />
              <div class="name">朱建阳</div>
              <div class="position">CEO/联合创始人</div>
              <div class="educational">毕业于清华大学计算机系</div>
              <div class="brief-introduce">
                拥有近30年企业级软件和云服务的行业和管理经验，曾在微软中国、惠普、神州数码等公司担任高层管理职位，具有德稻全球创新网络、银杏树科技、车百科技等初创公司的创业经历。
              </div>
            </div>
            <div class="introduce-people">
              <img
                class="people-img"
                src="../assets/companyPage/pic2.jpg"
                alt=""
              />
              <div class="name">王全勇</div>
              <div class="position">首席科学家/合伙人</div>
              <div class="educational">清华大学数学系学士、硕士</div>
              <div class="brief-introduce">
                25年软件开发与设计经验，曾在中软、RMG等国内外知名企业担任技术管理工作。具有丰富的软件架构设计及新技术研究及探索经验。
              </div>
            </div>
            <div class="introduce-people">
              <img
                class="people-img"
                src="../assets/companyPage/pic3.jpg"
                alt=""
              />
              <div class="name">林添</div>
              <div class="position">资深技术顾问/合伙人</div>
              <div class="educational">清华大学学士、硕士、博士</div>
              <div class="brief-introduce">
                GoogleBrain/DeepMind技术专家，TensorFlow中国团队联合创始人，曾在顶级会议ICML、NeurIPS、ICLR、KDD等机器学习相关领域发表多篇学术论文。
              </div>
            </div>
            <div class="introduce-people">
              <img
                class="people-img"
                src="../assets/companyPage/pic4.jpg"
                alt=""
              />
              <div class="name">戴冠平</div>
              <div class="position">行业大模型专家</div>
              <div class="educational">清华大学计算机系硕士</div>
              <div class="brief-introduce">
                先后任职于中国科学院软件研究所、BEA/ORACLE(中国);二十余年人工智能基础性研究工作经验，在大模型的研究与应用落地研究拥有丰富的实践经验;擅长多个模态上数据对齐、融合与智能学习。
              </div>
            </div>
          </div>
        </div>
        <div id="section10" :class="showMoreInfo" class="company-news">
          <div class="company-news-title">企业动态</div>
          <div class="news-list-box">
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/IWBdvGx_wctwKAw2AWV6ZQ')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/robot.png"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2024</div>
                <div>08-20</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">AI抢了谁的饭碗？</div>
                <div class="news-info">
                  2023年初，chatGPT3.5在世界范围内的一夜爆红,让所有人惊叹科幻电影中预测的场景提前到来。很多人,包括不少AI从业者,认为以chatGPT为代表的大模型的出现会让成千上万的人失业，将重构未来世界的产业架构与就业格局，“将成为人类历史上最重要的dis-ruptive
                  innovation”。甚至父母在给孩子确定大学专业的时候都会要问一句:“哪些专业今后不会被AI抢了饭碗?”
                </div>
              </div>
            </div>
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/ErwkvR3ZjmB2r8uZd9AV3g')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/cidpe.jpg"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2023</div>
                <div>05-29</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">
                  缔智元科技数字人:福州数字峰会的智慧新星
                </div>
                <div class="news-info">
                  第七届数字中国建设峰会在福州盛大开幕，以“释放数据要素价值,发展新质生产力”为主题，深度探索数字经济与实体经济的融合之道。在总面积达5.6万平方米的展区中，高科技产品云集。缔智元的数字人产品首次在数字峰会亮相,引人注目,成为本届峰会的智慧新星。
                </div>
              </div>
            </div>
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/BAD3jGp6QTE4oUGRMqSKLA')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/B2.jpg"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2024</div>
                <div>05-23</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">
                  产业园区智能化服务新星:企业数字员工
                </div>
                <div class="news-info">
                  缔智元科技为东升科技园带来了一位独特的“数字人”员工,这位员工已经入职东升科技园半年之久，她为园区的日常运营和服务注入了新的活力。
                </div>
              </div>
            </div>
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/NcnloOzwF3mz43sebk3khA')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/pic5.jpg"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2024</div>
                <div>04-19</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">
                  人工智能大模型发展趋势与企业级市场应用前景研讨会圆满落幕
                </div>
                <div class="news-info">
                  缔智元创始人CEO朱建阳在会场上为大家详细讲解了大模型在数字人领域的应用。他通过生动的案例展示了数字人在客户服务、会议预定、机场问询等方面的实际应用,让现场观众深刻感受到了大模型技术为数字人带来的快速发展。
                </div>
              </div>
            </div>
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/u9XJQumuVDzslK0ib1CPgQ')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/pic6.jpg"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2023</div>
                <div>09-22</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">
                  数字人2.0版本:大脑、视觉和听觉能力全面提升
                </div>
                <div class="news-info">
                  数字人2.0版本还引入了自然语言合成的能力,当用户提出问题时,数字人可以通过大模型的泛化理解，生成自然语言与访客进行流畅的对话。
                </div>
              </div>
            </div>
            <div @click="clickNews()" class="news-list">
              <!-- <img
              class="news-list-img"
              src="../assets/companyPage/pic7.jpg"
              alt=""
            /> -->
              <video
                class="news-list-img"
                controls
                src="../assets//companyPage/void.mp4"
              ></video>
              <div class="news-list-time">
                <div class="news-list-time-year">2023</div>
                <div>09-18</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">缔智元企业数字员工1.0版本上线!</div>
                <div class="news-info">
                  缔智元科技的数字员工1.0,正是瞄准了中小企业对企业前台的需求,用数字员工替代传统员工,完成访客预约与接待、会议室预定及会议组织、员工考勤管理、门禁及隐形视频监控、办公室场景控制、公共信息咨询等前台行政人员的重复性工作。
                </div>
              </div>
            </div>
            <div
              style="cursor: pointer"
              @click="
                clickNews('https://mp.weixin.qq.com/s/KyHxvhfQV6pvs116FHX1zA')
              "
              class="news-list"
            >
              <img
                class="news-list-img"
                src="../assets/companyPage/pic8.jpg"
                alt=""
              />
              <div class="news-list-time">
                <div class="news-list-time-year">2023</div>
                <div>08-30</div>
              </div>
              <div class="news-list-content">
                <div class="news-title">数字员工产品开创新前台工作模式</div>
                <div class="news-info">
                  “让每一家企业都拥有属于自己的数字员工是缔智元团队的创业理想”缔智元科技的数字员工“小酷“于今年二月份入职阳光恒昌,半年时间中,“小酷“学习新的技能,了解企业文化、提高各种业务能力,终于通过了试用期，成为一名正式的前台工作人员。
                </div>
              </div>
            </div>
          </div>
          <div
            @click="showMore"
            v-show="showMoreInfo == ''"
            class="show-more-box"
          >
            <div class="show-more">
              查看更多动态<img
                style="height: 1.3vw"
                src="../assets/companyPage/moreInfo.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../style/bootstrap.min.css";
import "../style/bootstrap.css";
export default {
  data() {
    return {
      showSpeakVideo: false,
      showNormalVideo: true,
      showMoreInfo: "",
    };
  },
  methods: {
    speakEnd() {
      this.showSpeakVideo = false;
    },
    clickNormalVideo() {
      console.log("cccccccc");
      this.showSpeakVideo = true;
      setTimeout(() => {
        console.log(this.$refs.videoPlayer);
        this.video = this.$refs.videoPlayer;
        console.log(this.video);
        this.video.volume = 1;
        this.video.play(); // 播放
      }, 200);
    },
    showMore() {
      this.showMoreInfo = "no-height";
    },
    clickNews(e) {
      if (e) {
        window.open(e);
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    goTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 可选，平滑滚动效果
      });
    },
  },
};
</script>
<style scoped>
@media screen and (orientation: landscape) {
  .out-box {
    width: 100%;
  }
  .goBack-box {
    text-align: center;
    position: fixed;
    right: 2vw;
    bottom: 20vh;
    width: 5vw;
    height: 10vw;
    background-color: rgb(15, 138, 205);
    border-radius: 8px;
    color: #fff;
    z-index: 99;
  }
  .goBack {
    border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .goBack-img {
    width: 3vw;
  }
  .goBack-word {
    display: none;
  }
  .goTop-img {
    width: 3vw;
  }
  .goTop {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .introductingCompany1 {
    width: 100%;
    height: 41.67vw;
    background-image: url("../assets//companyPage/introductingCompany1.jpg");
    background-size: 100% 100%;
  }
  .introduce {
    width: 51vw;
    color: #fff;
    padding-top: 16.67vw;
    padding-left: 12.5vw;
  }
  .introduce-title {
    font-size: 2.2vw;
  }
  .introduce-content {
    font-size: 1.2vw;
    line-height: 2.5vw;
  }
  .introduce-team-box {
    margin-top: 10vh;
    background-image: url("../assets/companyPage/introductingCompany3.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 74vw;
    padding-top: 7.81vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .introduce-team-title {
    width: 74.9vw;
    font-size: 2.2vw;
    font-weight: 900;
    text-align: center;
  }
  .introduce-team-content {
    width: 74.9vw;
    font-size: 1.62vw;
    text-align: center;
    margin-top: 5vh;
  }
  .introduce-people-box {
    width: 74.9vw;
    height: 38.96vw;
    margin-top: 4.38vw;
    display: flex;
    justify-content: space-between;
  }
  .introduce-people {
    width: 18.07vw;
    height: 100%;
    background-color: #fff;
  }
  .people-img {
    width: 100%;
  }
  .name {
    width: 100%;
    font-size: 1.32vw;
    font-weight: 900;
    text-align: center;
  }
  .position {
    width: 100%;
    font-size: 1.32vw;
    font-weight: 900;
    text-align: center;
  }
  .educational {
    text-align: center;
    font-size: 1.1vw;
    margin-top: 0.5vw;
  }
  .brief-introduce {
    padding: 0.5vw 1vw 0;
    font-size: 1.1vw;
    overflow: auto;
    height: 11vw;
  }
  .company-news {
    width: 100%;
    padding-top: 5vw;
    height: 107vh;
    overflow: hidden;
    position: relative;
  }
  .no-height {
    height: auto !important;
  }
  .company-news-title {
    text-align: center;
    font-size: 2.2vw;
    font-weight: 900;
  }
  .show-more-box {
    cursor: pointer;
    position: absolute;
    top: 101vh;
    background-color: rgb(2, 0, 37);
    border-radius: 2vw;
    color: #fff;
    left: 42vw;
    height: 6vh;
    width: 16vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .show-more {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    font-size: 1.3vw;
  }
  .news-list {
    width: 74.9vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12.55vw;
    margin-top: 5vw;
    margin-top: 7vh;
    height: 21vh;
  }
  .news-list-img {
    width: 16.57vw;
  }
  .news-list-time {
    font-size: 1.62vw;
    border-right: 1px dashed #ccc;
    padding-right: 3vw;
    color: #ccc;
  }
  .news-list-time-year {
    font-size: 2.2vw;
    font-weight: 600;
    color: #000;
  }
  .news-list-content {
    width: 38.54vw;
    font-size: 1.1vw;
  }
  .news-title {
    font-size: 1.62vw;
    font-weight: 700;
  }
}
@media screen and (orientation: portrait) {
  .out-box {
    width: 100%;
  }
  .goBack-box {
    text-align: center;
    position: fixed;
    right: 2vw;
    bottom: 20vh;
    width: 8vw;
    height: 15vw;
    background-color: rgb(15, 138, 205);
    border-radius: 1vw;
    z-index: 99;
    color: #fff;
  }
  .goBack {
    border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1vw;
  }
  .goBack-img {
    width: 4.5vw;
  }
  .goBack-word {
    display: none;
  }
  .goTop-img {
    width: 4.5vw;
  }
  .goTop {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .introductingCompany1 {
    width: 100%;
    height: 41.67vw;
    background-image: url("../assets//companyPage/introductingCompany1.jpg");
    background-size: 100% 100%;
  }
  .introduce {
    width: 51vw;
    color: #fff;
    padding-top: 16.67vw;
    padding-left: 12.5vw;
  }
  .introduce-title {
    font-size: 2.2vw;
  }
  .introduce-content {
    font-size: 1.2vw;
    line-height: 2.5vw;
  }
  .introduce-team-box {
    /* margin-top: 10vh; */
    background-image: url("../assets/companyPage/introductingCompany3.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 74vw;
    /* height: 64.9vw; */
    padding-top: 7.81vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .introduce-team-title {
    width: 74.9vw;
    font-size: 2.2vw !important;
    font-weight: 900 !important;
    text-align: center;
  }
  .introduce-team-content {
    width: 74.9vw;
    font-size: 1.62vw;
    text-align: center;
  }
  .introduce-people-box {
    width: 74.9vw;
    height: 38.96vw;
    margin-top: 4.38vw;
    display: flex;
    justify-content: space-between;
  }
  .introduce-people {
    width: 18.07vw;
    height: 100%;
    background-color: #fff;
  }
  .news-info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .people-img {
    width: 100%;
  }
  .name {
    width: 100%;
    font-size: 1.62vw;
    font-weight: 900;
    text-align: center;
  }
  .position {
    width: 100%;
    font-size: 1.62vw;
    font-weight: 900;
    text-align: center;
  }
  .educational {
    text-align: center;
    font-size: 1.4vw;
  }
  .brief-introduce {
    padding: 1vw 1vw 0;
    font-size: 1.1vw;
    overflow: auto;
    height: 11vw;
    /* display: none; */
  }
  .company-news {
    width: 100%;
    padding-top: 5vw;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
  }
  .company-news-title {
    text-align: center;
    font-size: 2.2vw;
    font-weight: 900;
  }
  .news-list {
    width: 74.9vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 12.55vw;
    margin-top: 5vw;
  }
  .news-list-img {
    width: 20.57vw;
  }
  .news-list-time {
    font-size: 1.62vw;
    border-right: 1px dashed #ccc;
    padding-right: 3vw;
    color: #ccc;
  }
  .news-list-time-year {
    font-size: 2.2vw;
    font-weight: 600;
    color: #000;
  }
  .news-list-content {
    width: 38.54vw;
    font-size: 1.1vw;
  }
  .news-title {
    font-size: 1.62vw;
    font-weight: 800;
  }
  .show-more-box {
    display: none;
  }
}
</style>

<style scoped>
/* 企业数字员工核心功能样式 */
.part2 .card {
  height: 66vh;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  border-radius: 20px;
}

.part8 .card {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  border: 0px solid #fff !important;
}
.part2 .card:hover,
.part8 .card:hover {
  /* cursor: pointer; */
  transform: translateY(-10px) scale(1.05);
  /* 兼容写法 */
  -webkit-transform: translateY(-10px) scale(1.05);
  -moz-transform: translateY(-10px) scale(1.05);
  -ms-transform: translateY(-10px) scale(1.05);
  -o-transform: translateY(-10px) scale(1.05);
  /* 阴影效果 */
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.1);
}
.part2 .card-text,
.introduce {
  color: #000;
  font-size: 1.2vw;
}
.card-title {
  font-size: 1.7vw;
  font-weight: 600;
  text-align: center;
  margin-top: 8%;
}
.card-body {
  height: 250px;
}
.introduce {
  /* padding:16px; */
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.intr {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: #2a2828;
}
.d_btn {
  position: absolute;
  top: 45%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9%;
  height: 10px;
  background: none;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  border: none;
}
.d_text {
  top: 30%;
}
.car_bt {
  padding: 14px 32px;
  background-color: #ff434f;
  margin-top: 30px;
  border: 0;
  color: var(--text-color-lightest);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}
.title {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 50px;
  font-size: 2.2vw;
  font-weight: 550;
}
.part4 .title1::after,
.part5 .title::after,
.part6 .title1::after,
.part7 .title1::after {
  width: 9%;
}
.part4 .title {
  margin-bottom: 0;
}
.feature-title {
  position: absolute;
  top: 40%;
  left: 37%;
  grid-area: title;
  font-size: 18px;
  color: var(--text-color-darker);
  color: #e7e9ec;
  /* background-color: chocolate; */
  background: rgba(188, 61, 20, 0.7);
  width: 80px;
  line-height: 30px;
  text-align: center;
  display: block;
  border-radius: 5px;
  font-size: 14px;
}
/* 企业数字员工六大应用场景样式 */
.part3 .col {
  margin-bottom: 35px;
}
.col-12 {
  margin-top: 20px;
}
.intro {
  font-size: 18px;
  text-align: center;
  color: #727272;
}
/* 智慧园区产品样式 */
.part4 .feature {
  width: 100%;
  height: 460px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
  padding: 16px;
  text-align: center;
}
.part4 h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}
.part4 .pt4_img {
  height: 80%;
}
/* 成功案例 */
.part5 .col div {
  height: 250px;
  overflow: hidden;
  margin-top: 34px;
}
.part5 .col img {
  max-width: 100%;
  max-height: 100%;
}
.pt5_wrap {
  height: 100%;
}
.part5 .row {
  margin-bottom: 25px;
}
.part6 {
  display: none;
}
.part6 .jcsa {
  justify-content: space-around !important;
}
/* 核心团队样式 */
.part6 .team-member {
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-bottom: 28px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  justify-items: center;
  height: 100%;
}
.part6 .team-member:hover {
  transform: translateY(-20px) scale(1.05);
  -webkit-transform: translateY(-20px) scale(1.05);
  -moz-transform: translateY(-20px) scale(1.05);
  -ms-transform: translateY(-20px) scale(1.05);
  -o-transform: translateY(-20px) scale(1.05);
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.1);
}
.part6 .name {
  margin-top: 16px;
  font-size: 22px;
  font-weight: 700;
}
.part6 .position {
  font-size: 16px;
  margin-bottom: 8px;
}
.profile-image {
  overflow: hidden;
}
.profile-image .imgb {
  height: 300px;
}
.profile-image img {
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
.pt7_bg {
  background: #727272;
  padding: 50px 0;
}
.part7 .col {
  text-align: center;
  height: 90px;
  line-height: 90px;
}
.part7 .col img {
  width: 100px;
}
/* 公司动态样式 */
.part8 img {
  border-radius: 10px;
}
.part8 .act-title {
  color: var(--text-color-dark);
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}

.date-published {
  color: #a39d9d;
  font-size: 14px;
}
.part8 .card-text {
  color: #8b8b8b;
  font-size: 14px;
  line-height: 24px;
}
.read-more-btn {
  border: 0;
  color: white;
  background-color: #ff434f;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 6px 14px;
  margin-top: 24px;
}
.part8 .meta {
  color: #c6c6c6;
  display: flex;
}
.part8 .comments {
  margin-left: 8px;
}
@media screen and (max-width: 991px) {
  /* 在这里写入当屏幕宽度小于或等于991px时的CSS规则 */
  /* 轮播图样式 */
  .carousel-indicators {
    display: none;
  }
  /* 企业数字员工核心功能 */
  .part2 .card {
    height: 400px;
    /* transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s; */
    margin-bottom: 10px;
  }
  .part2 .card:hover,
  .part8 .card:hover {
    cursor: pointer;
    transform: translateY(-10px) scale(1.05);
  }
  .card-img-top {
    width: 100%;
    /* height: 55%; */
  }
  .part2 .card-text,
  .introduce {
    font-size: 14px;
  }
  .part2 .card-body {
    padding: 0.8rem;
  }
  /* 企业数字员工六大应用场景 */
  .part3 .container {
    width: 100%;
    padding: 0;
    margin-right: auto;
  }
  .part3 .row {
    display: flex;
    margin: 0;
  }
  .part3 .col-12 {
    flex: 0 0 50%;
    max-width: 100%;
  }
  .part3 .feature-title {
    position: absolute;
    top: 40%;
    left: 31%;
    width: 60px;
    line-height: 20px;
    font-size: 10px;
    font-weight: 500;
  }
  /* 智慧园区产品样式 */
  .part4 .feature {
    /* width:100%; */
    height: 250px;
    padding-left: 10px;
  }
  .intro {
    font-size: 16px;
    padding: 0px 20px;
  }
  .part4 h4 {
    font-size: 14px;
  }
  .part4 .pt4_img {
    width: 99%;
    height: 75% !important;
  }
  .part4 :nth-of-type(2) .pt4_img {
    width: 98% !important;
  }
  .part4 :nth-of-type(3) .pt4_img {
    width: 50% !important;
  }
  .part4 :nth-of-type(5) .pt4_img {
    width: 76% !important;
  }
  .part4 .container {
    /* width: 100%; */
    padding: 0;
    margin-right: auto;
  }
  .part4 .row {
    display: flex;
    margin: 0;
  }
  .part4 .col-12 {
    flex: 0 0 50%;
    max-width: 100%;
  }
  /* 成功案例样式 */
  .part5 .container {
    width: 100%;
    padding: 0;
    margin-right: auto;
  }
  .part5 .row {
    display: flex;
    margin: 0;
  }
  .part5 .col-12 {
    flex: 0 0 50%;
    max-width: 100%;
  }
  /* 核心团队的样式 */
  .profile-image .imgb {
    height: 200px !important;
  }
  .profile-image img {
    width: 100% !important;
  }
  .intr {
    display: none;
  }
  .part6 .team-member {
    height: 90%;
  }
  .part6 .name {
    margin-top: 10px;
    font-size: 16px;
  }
  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 550;
  }
  /* 合作伙伴样式 */
  .pt7_bg {
    padding: 10px 0;
  }

  .row {
    text-align: center;
  }
  .part7 .col {
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .part7 .col img {
    width: 30px;
  }
  .col .millet {
    width: 28% !important;
  }
  .profile-image {
    overflow: hidden;
  }
  .profile-image .imgb {
    height: 100px;
  }
  .profile-image img {
    width: 50%;
    object-fit: cover;
    object-position: center top;
  }
}
@media screen and (orientation: portrait) {
  .success {
    font-size: 2rem !important;
  }
  h2 {
    font-size: 2.2vw !important;
  }
  .img_word {
    position: absolute;
    bottom: 20px;
    width: 83.57%;
    height: 19.8%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 2vw;
    border: 2px solid rgba(225, 225, 225, 0.6);
    left: 20px;
    padding: 1vw 0 0 2vw;
    color: #fff;
    text-align: start;
    p {
      font-size: 1.3vw;
    }
  }
  .part1 {
    margin-top: 10vh;
    position: relative;
  }
  .product-introduction {
    position: absolute;
    right: 20vw;
    bottom: 0vh;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
  }
  .top-title {
    font-size: 5vw;
    width: 100%;
    text-align: center;
    margin-left: 5%;
  }
  .top-body {
    font-size: 3vw;
  }
}
@media screen and (orientation: landscape) {
  .img_word {
    position: absolute;
    bottom: 20px;
    width: 83.57%;
    height: 19.8%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    border: 2px solid rgba(225, 225, 225, 0.6);
    left: 20px;
    padding: 20px 0 0 20px;
    color: #fff;
    p {
      font-size: 22px;
    }
  }
  /* 顶部轮播图 */
  .w-100 {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .product-introduction {
    position: absolute;
    right: 35%;
    bottom: 20%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
  }
  .top-title {
    font-size: 3.5vw;
    width: 100%;
    margin-left: -2.5%;
  }
  .top-body {
    font-size: 2vw;
    li {
      margin-top: 20px;
    }
  }
}
</style>