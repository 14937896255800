<template>
  <div class="out_box">
    <div class="contact_text">
      联&nbsp;&nbsp;&nbsp;&nbsp;系&nbsp;&nbsp;&nbsp;&nbsp;我&nbsp;&nbsp;&nbsp;&nbsp;们
    </div>
    <div class="contact_text3">
      联&nbsp;&nbsp;系&nbsp;&nbsp;我&nbsp;&nbsp;们
    </div>
    <div class="contact_text2">Contact Us</div>
    <div class="contact_style">
      <div class="word_style">
        <div class="comp_name">北京缔智元科技有限公司</div>
        <div class="email">
          <img class="img" src="../assets/email.png" alt="" />
          &nbsp;&nbsp;邮箱：mareting@digimeta.com.cn
        </div>
        <div class="address">
          <div>
            <div class="address_igmbox">
              <img class="img" src="../assets/address.png" alt="" />
              &nbsp;&nbsp;地址：北京市海淀区中关村东升科技园
            </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-北领地B3楼303</div>
          </div>
        </div>
      </div>
      <div class="code_style">
        <img class="code_img" src="/images/code.jpg" alt="" />
        <div class="code_text1">微信扫一扫关注公众号</div>
        <div class="code_text2">长按关注公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
/* 横板屏幕 */
@media screen and (orientation: landscape) {
  .out_box {
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/heng.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact_text {
    margin-top: 30.26vh;
    font-size: 5.19vh;
    color: #fff;
  }
  .contact_text3 {
    display: none;
  }
  .contact_text2 {
    margin-top: 3.333vh;
    color: #fff;
    font-size: 2.22vh;
  }
  .contact_style {
    width: 45.83vw;
    margin-top: 15.37vh;
    display: flex;
    justify-content: space-between;
  }
  .comp_name {
    font-size: 3.33vh;
    color: #fff;
  }
  .email {
    display: flex;
    align-items: center;
    margin-top: 5.19vh;
    margin-bottom: 3.33vh;
    font-size: 2.22vh;
    color: #fff;
  }
  .address_igmbox {
    display: flex;
    align-items: center;
  }
  .img {
    width: 2.22vh;
  }
  .address {
    font-size: 2.22vh;
    color: #fff;
    display: flex;
  }
  .code_style {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .code_img {
    width: 19.81vh;
  }
  .code_text1 {
    font-size: 2.22vh;
    color: #ccc;
    margin-top: 1.48vh;
  }
  .code_text2 {
    display: none;
    font-size: 2.22vh;
    color: #fff;
  }
}
/* 竖版屏幕 */
@media screen and (orientation: portrait) {
  .out_box {
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/shu.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 34.38vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact_text {
    display: none;
  }
  .contact_text3 {
    font-size: 4.9vh;
    color: #fff;
  }
  .contact_text2 {
    margin-top: 2.333vh;
    color: #fff;
    font-size: 2.22vh;
  }
  .contact_style {
    /* width: 45.83vw; */
    margin-top: 5.21vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .comp_name {
    font-size: 2.5vh;
    color: #fff;
    text-align: center;
  }
  .email {
    display: flex;
    align-items: center;
    margin-top: 5.19vh;
    margin-bottom: 1.98vh;
    font-size: 2.22vh;
    color: #fff;
  }
  .address_igmbox {
    display: flex;
    align-items: center;
  }
  .img {
    width: 2.78vw;
  }
  .address {
    font-size: 2.22vh;
    color: #fff;
    display: flex;
  }
  .code_style {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6.77vh;
  }
  .code_img {
    width: 11.25vh;
  }
  .code_text1 {
    display: none;
    font-size: 2.22vh;
    color: #fff;
    margin-top: 1.48vh;
  }
  .code_text2 {
    font-size: 1.5vh;
    margin-top: 1.48vh;
    color: #ccc;
  }
}
</style>